import { all } from 'typed-redux-saga';

import { authSaga } from './auth/auth.saga';
import { searchSaga } from './search/search.saga';
import { pdfSaga } from './pdf/pdf.saga';
import { notificationsSaga } from './notifications/notifications.saga';
import { exportDataSaga } from './exportData/exportData.saga';
import { mapSaga } from './map/map.saga';

export function* rootSaga() {
  yield* all([
    authSaga(),
    searchSaga(),
    pdfSaga(),
    notificationsSaga(),
    exportDataSaga(),
    mapSaga(),
  ]);
}
