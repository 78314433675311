import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../rootReducer';
import { LoadingStateKey } from './loadingState.slice';

export const loadingStateSelector = createSelector(
  (state: RootState, key: LoadingStateKey) => key,
  (state: RootState) => state.loadingState,
  (key, loadingState) => loadingState[key],
);
