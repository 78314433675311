export const config = {
  AUTH_URL: 'https://b.wa.needle-digital.com/auth/checklogin/',
  COGNITO_CLIENT_ID: '36nq2chhr5r13a62ivcmrur9pd',
  COGNITO_REDIRECT_URL: 'https://www.wa.needle-digital.com/auth/tokens/',
  COGNITO_RESPONSE_TYPE: 'code',
  COGNITO_AUTH_BASE_URL:
    'https://wa-needle.auth.ap-southeast-1.amazoncognito.com',
  COGNITO_AUTH_LOGIN_ENDPOINT: 'login/',
  AUTH_SERVER_URL: 'https://auth.wa.needle-digital.com',
  BACKEND_URL: 'https://b.wa.needle-digital.com/',
  PARENT_SITE_URL: 'https://needle-digital.com/',
  AUTH_SERVER_LOGOUT_ENDPOINT: 'logout/',
  COGNITO_AUTH_LOGOUT_ENDPOINT: 'logout',
  COGNITO_AUTH_FORGET_PASSWORD_ENDPOINT: 'forgotPassword',
  DSN: 'https://380a9483df774ef59e93f9f2ce90a2b3@o1135732.ingest.sentry.io/6186458',
  TRACE_SAMPLING_RATE: 0.2,
};
