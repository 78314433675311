import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Snackbar } from '@material-ui/core';
import { SnackbarCloseReason } from '@material-ui/core/Snackbar/Snackbar';
import { AlertColor } from '@material-ui/core/Alert/Alert';

export const Notifications: React.FC = () => {
  const [snackPack, setSnackPack] = useState<
    { message: string; key: number; type: AlertColor }[]
  >([]);
  const [open, setOpen] = useState<boolean>(false);
  const [messageInfo, setMessageInfo] = useState<{
    message: string;
    key: number;
    type: AlertColor;
  } | null>(null);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      setMessageInfo(snackPack[0]);
      setSnackPack((snackPack) => snackPack.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const showNotificationHandler = useCallback((event: CustomEvent) => {
    setSnackPack((snackPack: any) => [
      ...snackPack,
      {
        message: event.detail.message,
        key: new Date().getTime(),
        type: event.detail.type as AlertColor,
      },
    ]);
  }, []);

  useEffect(() => {
    // @ts-ignore
    window.addEventListener('@showNotification', showNotificationHandler, true);

    return () => {
      // @ts-ignore
      window.removeEventListener('@showNotification', showNotificationHandler);
    };
  }, [showNotificationHandler]);

  const closeHandler = (
    event: React.SyntheticEvent<any>,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const exitedHandler = () => {
    setMessageInfo(null);
  };

  return messageInfo ? (
    <Snackbar
      key={messageInfo ? messageInfo.key : undefined}
      open={open}
      autoHideDuration={6000}
      onClose={closeHandler}
      TransitionProps={{ onExited: exitedHandler }}
    >
      <Alert variant="filled" severity={messageInfo.type}>
        {messageInfo ? messageInfo.message : undefined}
      </Alert>
    </Snackbar>
  ) : null;
};
