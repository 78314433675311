import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const { actions, reducer: authReducer } = createSlice({
  name: 'auth',
  initialState: {
    loggedIn: false,
    user: {}, // todo @RA this field here is for compatibility with metronic components, should be removed
  },
  reducers: {
    login(state, _action: PayloadAction<string>) {
      state.loggedIn = true;
    },
    logout(state) {
      state.loggedIn = false;
    },
    register() {},
    setUser() {},
    validateToken() {},
  },
});

export const { login, logout, validateToken } = actions;
export { authReducer };
