/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
import React, { FC, useEffect, useState } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import axios from 'axios';

import { PrivateRoutes } from './PrivateRoutes';
import { config } from '../../config';

const LoadingComponent = () => <> Loading </>;

export const Routes: FC = () => {
  const [isAuthorized, setAuthorized] = useState(false);
  const [checkAuth, setCheckAuth] = useState(false);
  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const resp = await axios({
          method: 'GET',
          url: config.AUTH_URL,
          withCredentials: true,
        });
        setAuthorized(resp.data.loggedIn);
      } catch (err) {
        setAuthorized(false);
      }
      setCheckAuth(true);
    };
    checkLoginStatus();
  }, []);

  return (
    <Switch>
      <Route exact path="/">
        {checkAuth ? (
          isAuthorized ? (
            <PrivateRoutes />
          ) : (
            <AuthComponent />
          )
        ) : (
          <LoadingComponent />
        )}
      </Route>
      <Route path="/auth/tokens/" component={GetTokenComponent} />
      <Route path="/logout" component={LogoutComponent} />
    </Switch>
  );
};

export const AuthComponent = () => {
  useEffect(() => {
    window.location.href = `${config.COGNITO_AUTH_BASE_URL}/${config.COGNITO_AUTH_LOGIN_ENDPOINT}?client_id=${config.COGNITO_CLIENT_ID}&response_type=${config.COGNITO_RESPONSE_TYPE}&redirect_uri=${config.COGNITO_REDIRECT_URL}`;
  });

  return (
    <>
      <div>Redirecting to authentication page</div>
    </>
  );
};

export const LogoutComponent = () => {
  useEffect(() => {
    const logout = async () => {
      await axios({
        method: 'GET',
        url: `${config.AUTH_SERVER_URL}/${config.AUTH_SERVER_LOGOUT_ENDPOINT}`,
        withCredentials: true,
      });
      window.location.href = `${config.COGNITO_AUTH_BASE_URL}/${config.COGNITO_AUTH_LOGOUT_ENDPOINT}?client_id=${config.COGNITO_CLIENT_ID}&response_type=${config.COGNITO_RESPONSE_TYPE}&redirect_uri=${config.COGNITO_REDIRECT_URL}`;
    };
    logout();
  });

  return <>"Logging out"</>;
};
export const GetTokenComponent = (props: any) => {
  const [authComplete, setAuthComplete] = useState(false);
  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const code = query.get('code');
    const fetchTokens = async () => {
      await axios({
        method: 'GET',
        url: `${config.AUTH_SERVER_URL}`,
        params: {
          code,
        },
        withCredentials: true,
      });
      setAuthComplete(true);
    };
    fetchTokens();
  }, []);

  return <>{authComplete ? <Redirect to="/" /> : 'Authenticating'}</>;
};
