import { camelCase, isArray, isPlainObject } from 'lodash';

export const transformToCamelCase = (data: any): any => {
  if (!data) return data;

  if (isPlainObject(data)) {
    const result: any = {};

    Object.keys(data).forEach((field) => {
      result[camelCase(field)] = transformToCamelCase(data[field]);
    });

    return result;
  }

  if (isArray(data)) {
    return data.map((datum: any) => transformToCamelCase(datum));
  }

  return data;
};

export const generateTokenName = (token: string) => `Token ${token}`;
