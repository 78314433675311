import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  pdfUrl: string | null;
  pdfPage: number | null;
} = {
  pdfUrl: null,
  pdfPage: null,
};
const { actions, reducer: pdfReducer } = createSlice({
  name: 'pdf',
  initialState,
  reducers: {
    resetPdfParams() {
      return initialState;
    },
    loadPdf(state, _action: PayloadAction<{ page: number; fileId: number }>) {},
    loadPdfSuccess(
      state,
      action: PayloadAction<{
        url: string;
        page: number;
      }>,
    ) {
      const { page, url } = action.payload;
      state.pdfPage = page;
      state.pdfUrl = url;
    },
  },
});
export const { loadPdf, loadPdfSuccess, resetPdfParams } = actions;
export { pdfReducer };
