import { apiCall } from '../../../setup/axios/SetupAxios';

type ApiLoadPdfResponse = {
  redirectUrl: string;
};
export const apiLoadPdf = (rawFileId: number) =>
  apiCall<ApiLoadPdfResponse>({
    url: '/api/search/rawfile/forward',
    params: {
      rawfile_id: rawFileId,
    },
  });
