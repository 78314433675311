import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../rootReducer';
import { geoJSONAdapter } from './map.slice';

const mapSelector = (state: RootState) => state.map;

const adapterSelectors = geoJSONAdapter.getSelectors(mapSelector);

export const mapGeoJSONDataSelector = adapterSelectors.selectAll;
export const mapGeoJSONDataByReportNumSelector = adapterSelectors.selectById;

export const mapSelectedReportNumsSelector = createSelector(
  mapSelector,
  (map) => map.selectedReportNums,
);
export const mapEmptyGeoJsonReportNumsSelector = createSelector(
  mapSelector,
  (map) => map.emptyGeoJsonReportNums,
);
export const mapVisibleSelector = createSelector(
  mapSelector,
  (map) => map.mapVisible,
);

export const mapActiveGeoJSONDataSelector = createSelector(
  mapGeoJSONDataSelector,
  mapSelectedReportNumsSelector,
  (data, selectedReportNums) =>
    data.filter((datum) => selectedReportNums.includes(datum.reportNum)),
);
