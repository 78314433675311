import React, { useCallback, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { BasicModalProps } from '../constants';
import { loadingStateSelector } from '../../../../setup/redux/loadingState/loadingState.selectors';
import { RootState } from '../../../../setup';
import { setSearchParams } from '../../../../setup/redux/search/search.slice';
import {
  cancelLoadExportData,
  loadExportData,
} from '../../../../setup/redux/exportData/exportData.actions';

export const ExportData: React.FC<BasicModalProps> = ({ open, onClose }) => {
  const dispatch = useDispatch();

  const exportLoadingState = useSelector((state: RootState) =>
    loadingStateSelector(state, 'searchExportData'),
  );

  const [format, setFormat] = useState('csv');

  const exportHandler = useCallback(() => {
    dispatch(
      setSearchParams({
        format,
      }),
    );
    dispatch(loadExportData());
  }, [dispatch, format]);
  const setFormatHandler = useCallback((event) => {
    setFormat(event.target.value);
  }, []);

  const closeHandler = useCallback(() => {
    if (exportLoadingState.loading) {
      dispatch(cancelLoadExportData());
    }
    onClose();
  }, [dispatch, exportLoadingState.loading, onClose]);

  return (
    <Dialog
      open={open}
      onClose={closeHandler}
      PaperProps={{ sx: { minWidth: 400 } }}
    >
      <DialogTitle>Select export parameters</DialogTitle>
      <DialogContent>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="export-format-label">Export format</InputLabel>
          <Select
            value={format}
            onChange={setFormatHandler}
            labelId="export-format-label"
            id="export-format"
            label="Export format"
          >
            <MenuItem value="csv">CSV</MenuItem>
            <MenuItem value="xlsx">XLSX</MenuItem>
            <MenuItem value="json">JSON</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={exportHandler}
          disabled={exportLoadingState.loading}
        >
          Export
          {exportLoadingState.loading && (
            <CircularProgress color="inherit" size="1em" sx={{ ml: 0.5 }} />
          )}
        </Button>
        <Button onClick={closeHandler}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
