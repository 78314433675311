import { call, put, takeEvery } from 'typed-redux-saga';
import axios from 'axios';

import {
  destroyToken,
  getToken,
  saveToken,
} from '../../../api/services/jwt.service';
import { ActionType } from '../helpers/actionType';
import { login, logout, validateToken } from './auth.slice';
import { apiLoadUserByToken } from '../../../api/domains/auth/auth';
import { endLoading } from '../loadingState/loadingState.slice';
import { generateTokenName } from '../../axios/util';

function loginHandler(action: ActionType<typeof login>) {
  axios.defaults.headers.common.Authorization = generateTokenName(
    action.payload,
  );
  saveToken(action.payload);
}

function logoutHandler() {
  destroyToken();
  // todo @RA not the best approach
  window.location.reload();
}

function* validateTokenHandler() {
  const token = getToken();

  if (token) {
    try {
      yield* call(apiLoadUserByToken);
      yield* put(login(token));
    } catch (e) {
      destroyToken();
      yield* put(logout());
    } finally {
      yield* put(endLoading({ key: 'tokenVerification' }));
    }
  } else {
    yield* put(endLoading({ key: 'tokenVerification' }));
  }
}

export function* authSaga() {
  yield* takeEvery(login, loginHandler);
  yield* takeEvery(logout, logoutHandler);
  yield* takeEvery(validateToken, validateTokenHandler);
}
