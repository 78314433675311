import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { xor } from 'lodash';

import { GeoJSONResponseResult } from '../../../api/models/geoJSON';

export const geoJSONAdapter = createEntityAdapter<GeoJSONResponseResult>({
  selectId: ({ reportNum }) => reportNum,
});
type InitialState = {
  selectedReportNums: string[];
  selectionCoordinates: [number, number, number, number] | null;
  emptyGeoJsonReportNums: string[];
  mapVisible: boolean;
};
const initialState = geoJSONAdapter.getInitialState<InitialState>({
  selectedReportNums: [],
  selectionCoordinates: null,
  emptyGeoJsonReportNums: [],
  mapVisible: true,
});

const { actions, reducer: mapReducer } = createSlice({
  name: 'map',
  initialState,
  reducers: {
    toggleReportNum(state, _action: PayloadAction<string>) {},
    resetSelectedReportNums() {
      return initialState;
    },
    processReportNum(state, action: PayloadAction<string>) {
      state.selectedReportNums = xor(state.selectedReportNums, [
        action.payload,
      ]);
    },
    loadDataSuccess(
      state,
      action: PayloadAction<{ data: GeoJSONResponseResult; reportNum: string }>,
    ) {
      state.selectedReportNums.push(action.payload.reportNum);
      geoJSONAdapter.addOne(state, action.payload.data);
    },
    loadEmptyDataSuccess(state, action: PayloadAction<string>) {
      state.emptyGeoJsonReportNums.push(action.payload);
    },
    setMapVisible(state, action: PayloadAction<boolean>) {
      state.mapVisible = action.payload;
    },
  },
});

export const {
  toggleReportNum,
  resetSelectedReportNums,
  loadDataSuccess,
  processReportNum,
  loadEmptyDataSuccess,
  setMapVisible,
} = actions;
export { mapReducer };
