import React, {
  createContext,
  useCallback,
  ReactNode,
  ReactNodeArray,
} from 'react';
import {
  QueryParamProvider,
  useQueryParams,
  NumberParam,
  StringParam,
  DelimitedNumericArrayParam,
  DelimitedArrayParam,
} from 'use-query-params';
import { Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { every, isNumber, pickBy, identity } from 'lodash';
import { UrlUpdateType } from 'use-query-params/lib/types';

import {
  loadData,
  setSearchParams,
} from '../../../setup/redux/search/search.slice';
import { ClientSearchParams, SearchParams } from '../../../api/models/search';
import { ClientExportDataParams } from '../../../api/models/exportData';
import { MapSelectionRelation } from '../../../api/types/filters';

type CommonUrlParams = Partial<
  SearchParams & ClientSearchParams & ClientExportDataParams
>;
type Props = {
  children: ReactNode | ReactNodeArray;
};
export const SearchContext = createContext(
  {} as {
    search: (searchParams: Partial<SearchParams>) => void;
    setUrlParams: (
      urlParams: CommonUrlParams,
      updateType?: UrlUpdateType,
    ) => void;
    resetUrlParams: () => void;
    initialSearch: () => void;
  },
);
const Component: React.FC<Props> = ({ children }) => {
  const dispatch = useDispatch();

  const [query, setQuery] = useQueryParams({
    order: StringParam,
    search: StringParam,
    relation: StringParam,
    coordinates: DelimitedNumericArrayParam,
    page: NumberParam,
    searchType: StringParam,
    searchFields: DelimitedArrayParam,
    maxYear: NumberParam,
    minYear: NumberParam,
  });

  const setUrlParams = useCallback(
    (urlParams, updateType = 'pushIn') => {
      setQuery(urlParams, updateType);
    },
    [setQuery],
  );

  const resetUrlParams = useCallback(() => {
    setQuery({}, 'replace');
    dispatch(loadData());
  }, [dispatch, setQuery]);

  const search = useCallback(
    (params: Partial<SearchParams>) => {
      setQuery(pickBy(params, identity));

      dispatch(loadData());
    },
    [dispatch, setQuery],
  );

  const initialSearch = useCallback(() => {
    const { page, relation, coordinates } = query;
    const params = {
      ...query,
    };

    params.coordinates =
      coordinates?.length === 4 && every(coordinates, isNumber)
        ? coordinates
        : undefined;
    params.page = isNumber(page) ? page : 0;
    params.relation = params.coordinates
      ? (relation as MapSelectionRelation)
      : undefined;

    dispatch(setSearchParams(pickBy(params, identity)));
    dispatch(loadData());
  }, [dispatch, query]);

  return (
    <SearchContext.Provider
      value={{
        search,
        initialSearch,
        setUrlParams,
        resetUrlParams,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const SearchProvider: React.FC<Props> = ({ children }) => (
  <QueryParamProvider ReactRouterRoute={Route}>
    <Component>{children}</Component>
  </QueryParamProvider>
);
