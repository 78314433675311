import React, { FC, Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Notifications } from '../components/notifications/Notifications';
import { Modals } from '../components/modals/Modals';
import { SearchProvider } from '../providers/search/SearchProvider';

const FallbackView = () => <>Loading...</>;
export const PrivateRoutes: FC = () => {
  const WAMEXPageWrapper = lazy(() => import('../pages/search/WAMEXPage'));

  return (
    <Suspense fallback={<FallbackView />}>
      <SearchProvider>
        <WAMEXPageWrapper />
        <Modals />
      </SearchProvider>
      <Notifications />
    </Suspense>
  );
};
