import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { SearchItem } from '../../../api/models/search';

export const favItemsAdapter = createEntityAdapter<SearchItem>({
  selectId: ({ id }) => id,
});
const initialState = favItemsAdapter.getInitialState();
const { actions, reducer: favItemsReducer } = createSlice({
  name: 'favItems',
  initialState,
  reducers: {
    toggleFavItem(state, action: PayloadAction<SearchItem>) {
      if (state.entities[action.payload.id]) {
        favItemsAdapter.removeOne(state, action.payload.id);
      } else {
        favItemsAdapter.addOne(state, action.payload);
      }
    },
  },
});

export const { toggleFavItem } = actions;
export { favItemsReducer };
