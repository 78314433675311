import { createTheme, PaletteOptions } from '@material-ui/core';

const primaryColors = {
  main: '#111827',
};

const secondaryColors = {
  main: '#EAB308',
};

const palette = {
  primary: primaryColors,
  secondary: secondaryColors,
  background: {
    default: primaryColors.main,
    paper: '#eeeeee',
  },
  text: {
    primary: '#EAB308',
    secondary: '#111827',
    disabled: '#CCCCCC',
    light: '#FAFAFA',
  },
} as PaletteOptions;
export const theme = createTheme({
  palette,
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 12,
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1rem',
        },
      },
    },
  },
});
