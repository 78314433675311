import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  MapSelectionRelation,
  SearchField,
  SearchType,
} from '../../../api/types/filters';
import { SearchItem } from '../../../api/models/search';

export enum TableDisplayMode {
  All = 'all',
  Fav = 'fav',
}
type InitialState = {
  total: number;
  sentSearchTerm: string | null | undefined;
  displayMode: TableDisplayMode;
  params: {
    page: number;
    relation: MapSelectionRelation | null | undefined;
    coordinates: [number, number, number, number] | null | undefined;
    search: string | null | undefined;
    order: string | null | undefined;
    format: string | null | undefined;
    searchType: SearchType | null | undefined;
    searchFields: SearchField[] | null | undefined;
    maxYear: number | null | undefined;
    minYear: number | null | undefined;
    reportTypes: string | null | undefined;
    projects: string | null | undefined;
    companies: string | null | undefined;
    tenements: string | null | undefined;
  };
};
export const searchAdapter = createEntityAdapter<SearchItem>();
const initialState = searchAdapter.getInitialState<InitialState>({
  total: 0,
  sentSearchTerm: null,
  displayMode: TableDisplayMode.All,
  params: {
    page: 0,
    relation: undefined,
    coordinates: undefined,
    search: '',
    order: undefined,
    format: undefined,
    searchType: undefined,
    searchFields: undefined,
    minYear: undefined,
    maxYear: undefined,
    reportTypes: undefined,
    projects: undefined,
    companies: undefined,
    tenements: undefined,
  },
});
const { actions, reducer: searchReducer } = createSlice({
  name: 'search',
  initialState,
  reducers: {
    loadData(state) {
      state.sentSearchTerm = state.params.search;
    },
    loadDataSuccess(
      state,
      action: PayloadAction<{
        data: SearchItem[];
        total: number;
        currentPage: number;
      }>,
    ) {
      searchAdapter.setAll(state, action.payload.data);

      state.total = action.payload.total;
      state.params.page = action.payload.currentPage - 1;
    },
    setSearchParams(
      state,
      action: PayloadAction<Partial<InitialState['params']>>,
    ) {
      state.params = { ...state.params, ...action.payload };
    },
    resetSearchParams(state) {
      state.params = initialState.params;
      state.sentSearchTerm = initialState.sentSearchTerm;
    },
    setSelectionCoordinates(
      state,
      action: PayloadAction<[number, number, number, number] | null>,
    ) {
      state.params.coordinates = action.payload;
      // todo @RA not the best approach to keep this logic in slice, move to saga
      if (!state.params.relation) {
        state.params.relation = MapSelectionRelation.intersects;
      }
    },
    resetSelectionCoordinates(state) {
      state.params.coordinates = initialState.params.coordinates;
      state.params.relation = initialState.params.relation;
    },
    toggleDisplayMode(state) {
      state.displayMode =
        state.displayMode === TableDisplayMode.All
          ? TableDisplayMode.Fav
          : TableDisplayMode.All;
    },
  },
});

export const {
  loadData,
  loadDataSuccess,
  setSearchParams,
  resetSearchParams,
  setSelectionCoordinates,
  resetSelectionCoordinates,
  toggleDisplayMode,
} = actions;
export { searchReducer };
