import { takeEvery } from 'typed-redux-saga';

import { showNotification } from './notifications.actions';
import { ActionType } from '../helpers/actionType';

function showNotificationHandler(action: ActionType<typeof showNotification>) {
  window.dispatchEvent(
    new CustomEvent('@showNotification', {
      detail: {
        message: action.payload.message,
        type: action.payload.type,
      },
    }),
  );
}

export function* notificationsSaga() {
  yield* takeEvery(showNotification, showNotificationHandler);
}
