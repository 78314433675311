import { apiCall } from '../../../setup/axios/SetupAxios';
import { transformSearchParams, transformSearchUrl } from './utils';
import { MapSelectionRelation, SearchType } from '../../types/filters';

export type ApiSearchParams = {
  search: string | null | undefined;
  page: number | null | undefined;
  coordinates: [number, number, number, number] | null | undefined;
  relation: MapSelectionRelation | null | undefined;
  order: string | null | undefined;
  format: string | null | undefined;
  searchType: SearchType | null | undefined;
  maxYear: number | null | undefined;
  minYear: number | null | undefined;
  reportTypes: string | null | undefined;
  companies: string | null | undefined;
  projects: string | null | undefined;
  tenements: string | null | undefined;
};
export type ApiSearchItem = {
  abstract: string;
  authors: string[];
  bucketId: number;
  bucketName: string;
  dateFrom: string;
  dateReleased: string;
  dateTo: string;
  id: string;
  keywords: string[];
  operators: string[];
  pageNum: number;
  project: string;
  rawfileId: number;
  reportNum: string;
  targetCommodities: string[];
  tenementIds: string[];
  text: string;
  title: string;
  type: string;
  year: number;
  uri: string;
  highlight: Record<keyof ApiSearchItem, string[]>;
};
export type ApiSearchResponse = {
  count: number;
  current: number;
  facets: any;
  next: number | null;
  pageSize: number;
  previous: number | null;
  results: ApiSearchItem[];
};
export const apiSearch = (params: ApiSearchParams) =>
  apiCall<ApiSearchResponse>({
    method: 'get',
    url: transformSearchUrl(params),
    params: transformSearchParams(params),
  });
