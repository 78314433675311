import { combineReducers } from 'redux';

import { loadingStateReducer } from './loadingState/loadingState.slice';
import { authReducer } from './auth/auth.slice';
import { searchReducer } from './search/search.slice';
import { pdfReducer } from './pdf/pdf.slice';
import { mapReducer } from './map/map.slice';
import { modalsReducer } from './modals/modals.slice';
import { favItemsReducer } from './favItems/favItems.slice';

export const rootReducer = combineReducers({
  auth: authReducer,
  loadingState: loadingStateReducer,
  search: searchReducer,
  pdf: pdfReducer,
  map: mapReducer,
  modals: modalsReducer,
  favItems: favItemsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
