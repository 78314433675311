import { GeoJsonProperties, Geometry, Position } from 'geojson';

import { apiCall } from '../../../setup/axios/SetupAxios';

export type ApiGeoJSONResponse = {
  count: number;
  facets: {};
  next: null;
  previous: null;
  results: {
    geojsonData: {
      coordinates: Position[][];
      properties: GeoJsonProperties;
      type: Geometry;
    }[];
    id: number;
    reportNum: string;
  }[];
};
export const apiLoadGeoJSONData = (reportNum: string) =>
  apiCall<ApiGeoJSONResponse>({
    method: 'get',
    url: '/api/search/swa/geojson/',
    params: {
      report_num: reportNum,
    },
  });
