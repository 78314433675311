import { apiCall } from '../../../setup/axios/SetupAxios';
import { transformExportDataParams, transformExportDataUrl } from './utils';
import { MapSelectionRelation, SearchType } from '../../types/filters';

export type ApiLoadExportDataParams = {
  search: string | null | undefined;
  coordinates: [number, number, number, number] | null | undefined;
  relation: MapSelectionRelation | null | undefined;
  order: string | null | undefined;
  format: string | null | undefined;
  searchType: SearchType | null | undefined;
  maxYear: number | null | undefined;
  minYear: number | null | undefined;
};
export const apiLoadExportData = (params: ApiLoadExportDataParams) =>
  apiCall<string>({
    method: 'get',
    url: transformExportDataUrl(params),
    params: transformExportDataParams(params),
  });
