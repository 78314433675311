import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { pdfjs } from 'react-pdf';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import store from './setup/redux/store';
import { App } from './app/App';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { config } from './config';

const { PUBLIC_URL } = process.env;
// @ts-ignore
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

Sentry.init({
  dsn: config.DSN,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: config.TRACE_SAMPLING_RATE,
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    if (event.message) {
      Sentry.showReportDialog({
        eventId: event.event_id,
        title: 'User Feedback Form',
        labelComments: 'What would you like to share with us?',
        subtitle: '',
        subtitle2: '',
        labelSubmit: 'Submit',
      });
    }

    return event;
  },
});

ReactDOM.render(
  <Provider store={store}>
    <App basename={PUBLIC_URL || ''} />
  </Provider>,
  document.getElementById('root'),
);
