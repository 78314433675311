import { call, put, takeEvery } from 'typed-redux-saga';

import { apiLoadPdf } from '../../../api/domains/pdf/pdf';
import { endLoading, startLoading } from '../loadingState/loadingState.slice';
import { loadPdf, loadPdfSuccess } from './pdf.slice';
import { ActionType } from '../helpers/actionType';

function* loadPdfHandler(action: ActionType<typeof loadPdf>) {
  try {
    const { fileId } = action.payload;
    yield* put(startLoading({ key: 'pdf', data: fileId }));

    const {
      data: { redirectUrl: url },
    } = yield* call(apiLoadPdf, fileId);

    yield* put(
      loadPdfSuccess({
        url,
        page: action.payload.page,
      }),
    );
  } finally {
    yield* put(endLoading({ key: 'pdf' }));
  }
}

export function* pdfSaga() {
  yield* takeEvery(loadPdf, loadPdfHandler);
}
