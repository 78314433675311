import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';

import { Routes } from './routing/Routes';
import { theme } from '../setup/mui/theme';

type Props = {
  basename: string;
};

export const App: React.FC<Props> = ({ basename }) => (
  <BrowserRouter basename={basename}>
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  </BrowserRouter>
);
