import { call, cancel, fork, put, select, take } from 'typed-redux-saga';
import { saveAs } from 'file-saver';

import { cancelLoadExportData, loadExportData } from './exportData.actions';
import { endLoading, startLoading } from '../loadingState/loadingState.slice';
import { searchParamsSelector } from '../search/search.selectors';
import { apiLoadExportData } from '../../../api/domains/exportData/exportData';

function* loadExportDataHandler() {
  try {
    const params = yield* select(searchParamsSelector);

    yield* put(startLoading({ key: 'searchExportData' }));

    const { data, headers } = yield* call(apiLoadExportData, params);

    yield* put(endLoading({ key: 'searchExportData' }));

    const contentType = headers['content-type'];
    const blob = new Blob([data], { type: contentType });

    saveAs(blob, `export_data.${params.format}`);
  } finally {
    yield* put(endLoading({ key: 'searchExportData' }));
  }
}

export function* exportDataSaga() {
  while (yield* take(loadExportData)) {
    const loadExportDataTask = yield* fork(loadExportDataHandler);

    yield* take(cancelLoadExportData);
    yield* cancel(loadExportDataTask);
    yield* put(endLoading({ key: 'searchExportData' }));
  }
}
