export enum MapSelectionRelation {
  intersects = 'INTERSECTS',
  disjoint = 'DISJOINT',
  within = 'WITHIN',
}
export enum SearchType {
  Exact = 'exact',
  Fuzzy = 'fuzzy',
  Wildcard = 'wildcard',
}
export enum SearchField {
  Abstract = 'abstract',
  Title = 'title',
  Text = 'text',
  Keywords = 'keywords',
  Commodities = 'target_commodities',
  Tenements = 'tenement_ids',
}
