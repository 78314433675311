import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../rootReducer';

const modalsSelector = (state: RootState) => state.modals;

export const modalsOpenModalIdSelector = createSelector(
  modalsSelector,
  (modals) => modals.openModalId,
);
