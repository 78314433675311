import axios, { AxiosRequestConfig } from 'axios';
import { call } from 'typed-redux-saga';
import * as Sentry from '@sentry/react';

import { config } from '../../config';
import { transformToCamelCase } from './util';

const baseURL = config.BACKEND_URL;
axios.defaults.transformResponse = (data) => {
  try {
    const parsedData = JSON.parse(data);

    return transformToCamelCase(parsedData);
  } catch {
    return data;
  }
};
axios.defaults.baseURL = baseURL;
axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => {
    Sentry.captureException(error);
    window.dispatchEvent(
      new CustomEvent('@showNotification', {
        detail: {
          message: error.response?.data?.detail || error.message,
          type: 'error',
        },
      }),
    );

    return Promise.reject(error);
  },
);

export function* apiCall<T>(config: AxiosRequestConfig) {
  return yield* call(() => axios.request<T>(config));
}
