import { createSelector } from '@reduxjs/toolkit';
import { chain, identity } from 'lodash';

import { RootState } from '../rootReducer';
import { searchAdapter, TableDisplayMode } from './search.slice';
import { favItemsDataSelector } from '../favItems/favItems.selectors';

const searchSelector = (state: RootState) => state.search;
const adapterSelectors = searchAdapter.getSelectors(searchSelector);

export const searchAllDataSelector = adapterSelectors.selectAll;
export const searchTotalSelector = createSelector(
  searchSelector,
  (search) => search.total,
);
export const searchSentSearchTermSelector = createSelector(
  searchSelector,
  (search) => search.sentSearchTerm,
);
export const searchParamsSelector = createSelector(
  searchSelector,
  (search) => search.params,
);
export const searchDisplayModeSelector = createSelector(
  searchSelector,
  (params) => params.displayMode,
);
export const searchParamsRelationSelector = createSelector(
  searchParamsSelector,
  (params) => params.relation,
);
export const searchParamsCoordinatesSelector = createSelector(
  searchParamsSelector,
  (params) => params.coordinates,
);
export const searchParamsCurrentPageSelector = createSelector(
  searchParamsSelector,
  (params) => params.page,
);
export const searchParamsActiveFiltersCountSelector = createSelector(
  searchParamsSelector,
  (params) => chain(params).filter(identity).value().length,
);
export const searchParamsSearchTypeSelector = createSelector(
  searchParamsSelector,
  (params) => params.searchType,
);
export const searchParamsSearchFieldsSelector = createSelector(
  searchParamsSelector,
  (params) => params.searchFields,
);

export const searchDataSelector = createSelector(
  searchAllDataSelector,
  searchDisplayModeSelector,
  favItemsDataSelector,
  (data, displayMode, favData) =>
    displayMode === TableDisplayMode.All ? data : favData,
);
