import { identity, pickBy, snakeCase } from 'lodash';

import { createSelectionQuery } from '../utils';
import {
  ClientExportDataParams,
  LoadExportDataParams,
} from '../../models/exportData';

export const transformExportDataParams = (params: LoadExportDataParams) => {
  const {
    coordinates,
    relation,
    search,
    format,
    order,
    searchFields,
    minYear,
    maxYear,
  } = pickBy(params, identity) as Partial<
    LoadExportDataParams & ClientExportDataParams
  >;

  return {
    // todo @RA temp fix
    bucket_id: 2,
    search_simple_query_string: searchFields
      ? `${searchFields.join(',')}:${search}`
      : search,
    geojson_data__geo_shape: createSelectionQuery(coordinates, relation),
    ordering: order
      ? order.includes('-')
        ? `-${snakeCase(order)}`
        : snakeCase(order)
      : undefined,
    export: 'true',
    format,
    year__range: `${minYear}__${maxYear}`,
  };
};

export const transformExportDataUrl = (params: LoadExportDataParams) =>
  `/api/search/swa/${params.searchType || 'exact'}/`;
