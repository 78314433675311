import { call, put, select, takeEvery } from 'typed-redux-saga';

import {
  apiSearch,
  ApiSearchResponse,
} from '../../../api/domains/search/search';
import { loadData, loadDataSuccess } from './search.slice';
import { endLoading, startLoading } from '../loadingState/loadingState.slice';
import { searchParamsSelector } from './search.selectors';
import { formatText, mergeText } from './search.utils';

function* loadDataHandler() {
  try {
    const params = yield* select(searchParamsSelector);

    yield* put(startLoading({ key: 'searchData' }));
    let data: ApiSearchResponse;
    if (
      params.search ||
      params.coordinates ||
      params.projects ||
      params.companies ||
      params.reportTypes
    ) {
      data = (yield* call(apiSearch, params)).data;
    } else
      data = {
        count: 0,
        current: 1,
        results: [],
        facets: [],
        next: null,
        pageSize: 200,
        previous: null,
      };

    yield* put(
      loadDataSuccess({
        data: mergeText(formatText(data.results)),
        total: data.count,
        currentPage: data.current,
      }),
    );
  } catch (error) {
    //
  } finally {
    yield* put(endLoading({ key: 'searchData' }));
  }
}

export function* searchSaga() {
  yield* takeEvery(loadData, loadDataHandler);
}
