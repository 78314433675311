import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ModalId } from './modals.constants';

type InitialState = {
  openModalId: ModalId | null;
};
const initialState: InitialState = {
  openModalId: null,
};
const { actions, reducer: modalsReducer } = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModal(state, action: PayloadAction<ModalId>) {
      state.openModalId = action.payload;
    },
    closeModal(state) {
      state.openModalId = null;
    },
  },
});

export const { openModal, closeModal } = actions;
export { modalsReducer };
