import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialState<T extends string> = Record<
  T,
  {
    loading: boolean;
    message?: string;
    data?: string | number | null;
  }
>;
const initialState = {
  tokenVerification: {
    loading: true,
  },
  searchData: {
    loading: false,
  },
  pdf: {
    loading: false,
    message: '',
    data: null,
  },
  searchExportData: {
    loading: false,
  },
  geoJSON: {
    loading: false,
    data: null,
  },
};
export type LoadingStateKey = keyof typeof initialState;
const { actions, reducer: loadingStateReducer } = createSlice({
  name: 'loadingState',
  initialState: initialState as InitialState<keyof typeof initialState>,
  reducers: {
    startLoading(
      state,
      action: PayloadAction<{
        key: LoadingStateKey;
        message?: string;
        data?: string | number;
      }>,
    ) {
      const { key, message = '', data = null } = action.payload;

      state[key] = {
        loading: true,
        message,
        data,
      };
    },
    endLoading(
      state,
      action: PayloadAction<{
        key: LoadingStateKey;
        message?: string;
      }>,
    ) {
      const { key, message = '' } = action.payload;

      state[key] = {
        loading: false,
        message,
        data: null,
      };
    },
  },
});

export const { startLoading, endLoading } = actions;
export { loadingStateReducer };
