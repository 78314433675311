import { MapSelectionRelation } from '../types/filters';

export const createSelectionQuery = (
  coordinates: [number, number, number, number] | null | undefined,
  relation: MapSelectionRelation | null | undefined,
) => {
  if (!coordinates || !relation) return undefined;

  const [x1, y1, x2, y2] = coordinates;

  return `${x1},${y1}__${x2},${y2}__relation,${relation}__type,envelope`;
};
