import { identity, pickBy, snakeCase } from 'lodash';

import { createSelectionQuery } from '../utils';
import { ClientSearchParams, SearchParams } from '../../models/search';
import { SearchFields } from '../../../app/components/searchBar/constants';
import { SearchField } from '../../types/filters';

export const transformSearchParams = (params: SearchParams) => {
  const {
    coordinates,
    page,
    relation,
    search,
    order,
    searchFields,
    minYear,
    maxYear,
    reportTypes,
    projects,
    companies,
    tenements,
  } = pickBy(params, identity) as Partial<SearchParams & ClientSearchParams>;
  const textFields = SearchFields.filter((s) => s.type === 'text').map(
    (s) => s.value,
  );

  const listFields = SearchFields.filter((s) => s.type === 'list').map(
    (s) => s.value,
  );

  const currTextFields = searchFields?.filter((s) =>
    textFields.includes(s as SearchField),
  );
  const currListFields = searchFields?.filter((s) =>
    listFields.includes(s as SearchField),
  );
  const searchQueryParam =
    params.searchType === 'exact' ? 'search_simple_query_string' : 'search';

  const retVal: any = {
    // todo @RA temp fix
    bucket_id: 2,
    page: page || 1,
    geojson_data__geo_shape: createSelectionQuery(coordinates, relation),
    ordering: order
      ? order.includes('-')
        ? `-${snakeCase(order)}`
        : snakeCase(order)
      : undefined,
    highlight: `${currTextFields ? currTextFields.join(',') : 'text'}`,
    year__range: `${minYear || 1750}__${maxYear || new Date().getFullYear()}`,
  };

  if (currTextFields && currTextFields.length > 0 && search)
    retVal[searchQueryParam] = `${currTextFields.join(',')}:${search}`;
  if (currListFields)
    currListFields.forEach((c) => {
      retVal[`${c}`] = search;
    });
  if (reportTypes) retVal.type__in = reportTypes;
  if (companies) retVal.operators__in = companies;
  if (projects) retVal.project__in = projects;
  if (tenements) retVal.tenement_ids__in = tenements;

  return retVal;
};

export const transformSearchUrl = (params: SearchParams) =>
  `/api/search/swa/${params.searchType || 'exact'}/`;
