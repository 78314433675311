import { call, put, select, takeEvery } from 'typed-redux-saga';

import {
  loadDataSuccess,
  loadEmptyDataSuccess,
  processReportNum,
  toggleReportNum,
} from './map.slice';
import { endLoading, startLoading } from '../loadingState/loadingState.slice';
import { apiLoadGeoJSONData } from '../../../api/domains/geoJSON/geoJSON';
import { ActionType } from '../helpers/actionType';
import { mapGeoJSONDataByReportNumSelector } from './map.selectors';
import { RootState } from '../rootReducer';
import { showNotification } from '../notifications/notifications.actions';

function* toggleReportNumHandler(action: ActionType<typeof toggleReportNum>) {
  const data = yield* select((state: RootState) =>
    mapGeoJSONDataByReportNumSelector(state, action.payload),
  );

  if (data) {
    yield* put(processReportNum(action.payload));

    return;
  }

  try {
    yield* put(startLoading({ key: 'geoJSON', data: action.payload }));

    const { data } = yield* call(apiLoadGeoJSONData, action.payload);
    const result = data.results[0];

    if (Array.isArray(result.geojsonData)) {
      yield* put(
        loadDataSuccess({
          data: result,
          reportNum: action.payload,
        }),
      );
    } else {
      yield* put(loadEmptyDataSuccess(action.payload));
      yield* put(
        showNotification({
          type: 'warning',
          message: 'This report does not contain geo data',
        }),
      );
    }
  } finally {
    yield* put(endLoading({ key: 'geoJSON' }));
  }
}

export function* mapSaga() {
  yield* takeEvery(toggleReportNum, toggleReportNumHandler);
}
