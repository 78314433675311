import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ExportData } from './exportData/ExportData';
import { modalsOpenModalIdSelector } from '../../../setup/redux/modals/modals.selectors';
import { closeModal } from '../../../setup/redux/modals/modals.slice';

export const Modals: React.FC = () => {
  const dispatch = useDispatch();

  const openModalId = useSelector(modalsOpenModalIdSelector);

  const closeHandler = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  return (
    <>
      <ExportData open={openModalId === 'exportData'} onClose={closeHandler} />
    </>
  );
};
