import { split } from 'lodash';

import { SearchItem } from '../../../api/models/search';

export const formatText = (data: SearchItem[]) =>
  data.map((datum) => {
    (Object.keys(datum.highlight) as Array<keyof SearchItem>).forEach(
      (field) => {
        datum.highlight[field].forEach((text) => {
          let result = datum[field];
          let isArray = false;
          if (Array.isArray(result)) {
            result = result.join('___');
            isArray = true;
          }
          if (typeof result === 'string') {
            const textToSearch = split(text, new RegExp('<b>|<\\/b>')).join('');
            if (result.includes(textToSearch)) {
              if (isArray) {
                // @ts-ignore
                datum[field] = result.replace(textToSearch, text).split('___');
              } else {
                (datum[field] as string) = result.replace(textToSearch, text);
              }
            }
          }
        });
      },
    );

    return datum;
  });

export const mergeText = (data: SearchItem[]) => {
  const newData: any = {};
  data.forEach((datum) => {
    if (newData[datum.reportNum]) {
      newData[
        datum.reportNum
      ].text += `<br><b>Page ${datum.pageNum}</b><br>${datum.text}`;
    } else {
      newData[datum.reportNum] = datum;
      newData[
        datum.reportNum
      ].text = `<br><b>Page ${datum.pageNum}</b><br>${datum.text}`;
    }
  });

  return Object.values(newData) as SearchItem[];
};
