import { SearchType, SearchField } from '../../../api/types/filters';

export const SearchTypes = [
  {
    value: SearchType.Exact,
    text: 'Exact',
    description: 'Description of exact',
  },
  {
    value: SearchType.Fuzzy,
    text: 'Fuzzy',
    description: 'Description of fuzzy',
  },
  {
    value: SearchType.Wildcard,
    text: 'Wildcard',
    description: 'Description of wildcard',
  },
];

export const SearchFields = [
  { value: SearchField.Abstract, text: 'Abstract', type: 'text' },
  { value: SearchField.Title, text: 'Title', type: 'text' },
  { value: SearchField.Text, text: 'Text', type: 'text' },
  { value: SearchField.Keywords, text: 'Keywords', type: 'text' },
  { value: SearchField.Commodities, text: 'Commodities', type: 'text' },
  { value: SearchField.Tenements, text: 'Tenements', type: 'text' },
];
